





































import Vue from "vue";
import Timer from "@/components/Timer.vue";
import Footer from "@/components/navigation-footer.vue";
import { mapGetters } from "vuex";
export default Vue.extend({
  components: {
    Timer,
    Footer,
  },
  computed: {
    ...mapGetters({
      values: "primaryValues",
    }),
    sentences: function () {
      const sentences: { sentence: string; primaryValue: string }[] = [];
      for (let i = 0; i < this.values.length; i++) {
        sentences.push({
          sentence: "",
          primaryValue: this.values[i],
        });
      }
      return sentences;
    },
  },
  mounted: function () {
    this.$store.dispatch("SetLogoStyle", { color: "dark" });
    this.$store.dispatch("SetLogoBottomStyle", { color: "light" });
  },
  methods: {
    UpadateSentences: function () {
      this.$store.dispatch("UpdateSentences", { sentences: this.sentences });
    },
  },
});
